const products = [
  {
    id: 1,
    title: "Tomato - टमाटर",
    quantity: "1 kg",
    img: "img/vegetables/tomato.jpg",
    price: 20,
    category: "vegetables",
    company: "BLT STORE",
    iinfo: "The fresh handpicked tomatoes, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 2,
    title: "Potato - आलू",
    quantity: "1 kg",
    img: "img/vegetables/potato.jpg",
    price: 23,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked potatoes, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 3,
    title: "Ginger - अदरक",
    quantity: "100 g",
    img: "img/vegetables/ginger.jpg",
    price: 0,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked gingers, price might change day by day.",
    inCart: false,
    inStock: false,
    count: 0,
    total: 0,
  },
  {
    id: 4,
    title: "Onion - प्याज",
    quantity: "500 g",
    img: "img/vegetables/onion.jpg",
    price: 13,
    company: "BLT STORE",
    category: "vegetables",
    iinfo: "The fresh handpicked onions, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 5,
    title: "Spring Onion - हरा प्याज,",
    quantity: "1 kg",
    img: "img/vegetables/spring-onion.jpg",
    price: 20,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked spring onions, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 6,
    title: "Spinach - पालक",
    quantity: "500 g",
    img: "img/vegetables/spinach.jpg",
    price: 15,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked spinach, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 7,
    title: "Garlic - लहशुन",
    quantity: "1 kg",
    img: "img/vegetables/garlic.jpg",
    price: 0,
    company: "BLT STORE",
    category: "vegetables",
    info: "The fresh handpicked garlics, price might change day by day.",
    inCart: false,
    inStock: false,
    count: 0,
    total: 0,
  },
  {
    id: 8,
    title: "Green Chilli - हरी मिर्च",
    quantity: "500 g",
    img: "img/vegetables/green-chilli.jpg",
    price: 12,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked green chillis, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 9,
    title: "Lady Finger - भिन्डी",
    quantity: "500 g",
    img: "img/vegetables/lady-finger.jpg",
    price: 30,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked lady fingers, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 10,
    title: "Peppermint - पुदीना",
    quantity: "500 g",
    img: "img/vegetables/peppermint.jpg",
    price: 0,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked peppermints, price might change day by day.",
    inCart: false,
    inStock: false,
    count: 0,
    total: 0,
  },
  {
    id: 11,
    title: "Ridge Gourd - तोरी",
    quantity: "500 g",
    img: "img/vegetables/ridge-gourd.jpg",
    price: 30,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked ridged gourd, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 12,
    title: "Cucumber - खीरा",
    quantity: "1 kg",
    img: "img/vegetables/cucumber.jpg",
    price: 0,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked cucumbers, price might change day by day.",
    inCart: false,
    inStock: false,
    count: 0,
    total: 0,
  },
  {
    id: 13,
    title: "Coriander Leaves - हरा धनिया",
    quantity: "250 g",
    img: "img/vegetables/coriander-leaves.jpg",
    price: 10,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked coriander leaves, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 14,
    title: "Cluster Beans - गवार फली",
    quantity: "500 g",
    img: "img/vegetables/cluster-beans.jpg",
    price: 30,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked cluster beans, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 15,
    title: "Cauliflower - फूल गोभी",
    quantity: "1 kg",
    img: "img/vegetables/cauliflower.jpg",
    price: 44,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked cauliflowers, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 16,
    title: "Cabbage - पत्ता गोभी",
    quantity: "1 kg",
    img: "img/vegetables/cabbage.jpg",
    price: 20,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked cabbages, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 17,
    title: "Brinjal - बैगन",
    quantity: "1 kg",
    img: "img/vegetables/brinjal.jpg",
    price: 30,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked brinjals, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 18,
    title: "Bottle Gourd - लौकी",
    quantity: "1 kg",
    img: "img/vegetables/bottle-gourd.jpg",
    price: 30,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked bottle gourds, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 19,
    title: "Cucumis Utilissimus - ककड़ी",
    quantity: "1 kg",
    img: "img/vegetables/cucumis-utilissimus.jpg",
    price: 21,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked cucumis utilissimus, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 20,
    title: "Bitter Gourd - करेला",
    quantity: "500 g",
    img: "img/vegetables/bitter-gourd.jpg",
    price: 20,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked bitter gourds, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 21,
    title: "Lemon - नींबू",
    quantity: "2 pc",
    img: "img/vegetables/lemon.jpg",
    price: 12,
    company: "BLT STORE",
    category: "vegetables",
    info: "The handpicked lemons, price might change day by day",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 101,
    title: "Mango Almond- बादाम आम",
    quantity: "1 kg",
    img: "img/fruits/mango.jpg",
    price: 136,
    company: "BLT STORE",
    category: "fruits",
    info:
      "The handpicked mangoes. Original product's size and color may very. Price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 102,
    title: "Mango Alphonso - हाफुस आम",
    quantity: "1 kg",
    img: "img/fruits/alphonso-mango.jpg",
    price: 490,
    company: "BLT STORE",
    category: "fruits",
    info:
      "The handpicked mangoes. Original product's size and color may very. Price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 103,
    title: "Apple - एप्पल",
    quantity: "1 kg",
    img: "img/fruits/apple.jpg",
    price: 200,
    company: "BLT STORE",
    category: "fruits",
    info: "The handpicked Apple, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 104,
    title: "Mango - सुंदरी आम",
    quantity: "1 kg",
    img: "img/fruits/lalbagh-mango-sindhura.jpg",
    price: 220,
    company: "BLT STORE",
    category: "fruits",
    info:
      "The handpicked mangoes. Original product's size and color may very. Price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 105,
    title: "Banana - केला",
    quantity: "1 kg",
    img: "img/fruits/banana.jpg",
    price: 24,
    company: "BLT STORE",
    category: "fruits",
    info: "The handpicked bananas, price might change day by day",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 106,
    title: "Coconut - नारियल",
    quantity: "1 pc",
    img: "img/fruits/coconut.jpg",
    price: 40,
    company: "BLT STORE",
    category: "fruits",
    info: "The handpicked coconuts, price might change day by day",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 107,
    title: "Grapes - अंगूर्",
    quantity: "1 Kg",
    img: "img/fruits/grapes.jpg",
    price: 44,
    company: "BLT STORE",
    category: "fruits",
    info: "The handpicked grapes, price might change day by day",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },

  {
    id: 108,
    title: "Musk-melon - खरबुजा",
    quantity: "1 Kg",
    img: "img/fruits/musk-melon.jpg",
    price: 36,
    company: "BLT STORE",
    category: "fruits",
    info: "The handpicked musk-melons, price might change day by day",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 109,
    title: "Orange - संतरा",
    quantity: "1 Kg",
    img: "img/fruits/orange.jpg",
    price: 56,
    company: "BLT STORE",
    category: "fruits",
    info: "The handpicked oranges, price might change day by day",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 110,
    title: "Papaya - पपीता",
    quantity: "1 Kg",
    img: "img/fruits/papaya.jpg",
    price: 33,
    company: "BLT STORE",
    category: "fruits",
    info: "The handpicked papayas, price might change day by day",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 111,
    title: "Pomegranate - अनार्",
    quantity: "1 Kg",
    img: "img/fruits/pomegranate.jpg",
    price: 0,
    company: "BLT STORE",
    category: "fruits",
    info: "The handpicked pomegranates, price might change day by day",
    inCart: false,
    inStock: false,
    count: 0,
    total: 0,
  },
  {
    id: 112,
    title: "Water-melon - तरबूज",
    quantity: "1 Kg",
    img: "img/fruits/water-melon.jpg",
    price: 34,
    company: "BLT STORE",
    category: "fruits",
    info: "The handpicked water-melons , price might change day by day",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 113,
    title: "Citrus limetta - मौसमी",
    quantity: "1 Kg",
    img: "img/fruits/citrus-limetta.jpg",
    price: 53,
    company: "BLT STORE",
    category: "fruits",
    info: "The handpicked sweet oranges, price might change day by day",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 1090,
    title: "Water-melon - तरबूज",
    quantity: "1 kg",
    img: "img/fruits/water-melon.jpg",
    price: 34,
    company: "BLT STORE",
    category: "immunity",
    info: "The handpicked water-melons, price might change day by day",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 2010,
    title: "Ginger",
    quantity: "1 kg",
    img: "img/vegetables/ginger.jpg",
    price: 0,
    company: "BLT STORE",
    category: "immunity",
    info: "The handpicked gingers, price might change day by day",
    inCart: false,
    inStock: false,
    count: 0,
    total: 0,
  },
  {
    id: 1070,
    title: "Papaya - पपीता",
    quantity: "1 Kg",
    img: "img/fruits/papaya.jpg",
    price: 33,
    company: "BLT STORE",
    category: "immunity",
    info: "The handpicked papayas, price might change day by day",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 60,
    title: "Spinach - पालक",
    quantity: "500 g",
    img: "img/vegetables/spinach.jpg",
    price: 18,
    company: "BLT STORE",
    category: "immunity",
    info: "The handpicked spinach, price might change day by day.",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
  {
    id: 70,
    title: "Garlic - लहशुन )",
    quantity: "1 kg",
    img: "img/vegetables/garlic.jpg",
    price: 0,
    company: "BLT STORE",
    category: "immunity",
    iinfo: "The fresh handpicked garlics, price might change day by day.",
    inCart: false,
    inStock: false,
    count: 0,
    total: 0,
  },
  {
    id: 1060,
    title: "Orange - संतरा",
    quantity: "1 Kg",
    img: "img/fruits/orange.jpg",
    price: 50,
    company: "BLT STORE",
    category: "immunity",
    info: "The handpicked oranges, price might change day by day",
    inCart: false,
    inStock: true,
    count: 0,
    total: 0,
  },
];

export default products;
