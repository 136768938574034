export const mainProducts = [
  {
    id: 1,
    title: "vegetables",
    img: "img/mainCategory/vege.jpg",
    info: "Fresh vegetables",
    category: "vegetables",
  },
  {
    id: 2,
    title: "fruits",
    img: "img/mainCategory/fruits.jpg",
    info: "Fresh fruits",
    category: "fruits",
  },
  {
    id: 3,
    title: "immunity",
    img: "img/mainCategory/immunity.jpg",
    info: "Fresh fruits",
    category: "immunity",
  },
  // {
  //   id: 4,
  //   title: "immunity",
  //   img: "img/mainCategory/fastfood.jpg",
  //   info: "Fast Food",
  //   category: "#",
  // },
  {
    id: 5,
    title: "immunity",
    img: "img/mainCategory/groceries.jpg",
    info: "Fresh groceries",
    category: "#",
  },
];
